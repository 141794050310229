<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">客戶訂單</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button
          v-show="!grid.isEditing && canCreate"
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="grid.addNewRow"
        >
          <FontAwesome
            icon="plus"
            type="fas"
            class="w-4 h-4 mr-1"
          />
          新增
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字"
              @keyup.enter="grid.refresh"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.refresh"
            >
              搜尋
            </button>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid
          ref="grid"
          v-bind="gridOptions"
          @addNewRow="initAddData"
          @edit="onGridEdit"
          @removeSelectedRows="hideBatchDropDown()"
        >
          <template #flow="{ row }">
            <vxe-button
              type="button"
              content="查看"
              @click="flowModal.show(row)"
            ></vxe-button>
          </template>
          <template #modal="{ row, submit, reset }">
            <vxe-form
              ref="form"
              v-bind="formOptions"
              :data="row"
              @reset="reset"
              @submit="formSubmit(row, submit)"
            >
              <template #column-file="{ data }">
                <DocumentFileUpload
                  v-if="data.Id"
                  ref="fileGrid"
                  :title="''"
                  v-bind="fileModal.uploadOptions"
                  :canUpload="!readType"
                  :canDelete="!readType"
                />
              </template>
              <template #column-submitter="{ data }">
                <select-box
                  v-if="data"
                  v-bind="userSelectorOptions"
                  :readonly="readType"
                  :disabled="readType"
                  v-model="data.SubmitterId"
                />
              </template>
              <template #column-department="{ data }">
                <select-box
                  v-if="data.XmlData"
                  v-bind="departmentSelectorOptions"
                  :readonly="readType"
                  :disabled="readType"
                  v-model="data.XmlData.DepartmentId"
                />
              </template>
              <template #column-customName="{ data }">
                <select-box
                  v-if="data.XmlData"
                  v-bind="customNameSelectOption"
                  :readonly="readType"
                  :disabled="readType"
                  v-model="data.XmlData.CustomerName"
                />
              </template>
              <!-- 客戶訂單 14 -->
              <template #column-OrderItems="{ data }">
                <OrderItemsTable
                  v-if="data.XmlData"
                  ref="OrderItemsTable"
                  :aid="data.Id"
                  @refresh="refresh"
                  :data="data.XmlData.Items"
                  :readType="readType"
                />
              </template>
              <!-- 客戶訂單 14 -->
            </vxe-form>
          </template>
          <template #modal-footer="{ row }">
            <div class="flex justify-between">
              <div>
                <vxe-button
                  v-if="row.Id && row.Status == 0"
                  type="button"
                  status="primary"
                  content="送出文件"
                  @click="sendDocument(row)"
                ></vxe-button>
              </div>
              <div>
                <vxe-button
                  v-if="row.Status !== 0"
                  type="button"
                  status="primary"
                  content="關閉"
                  @click="() => { grid.isModalPopup = false; grid.editingRow = null; }"
                ></vxe-button>
                <vxe-button
                  v-else
                  type="submit"
                  status="primary"
                  content="確認"
                  @click="$refs.form.validate(errMap => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })"
                ></vxe-button>
              </div>
            </div>
            <!-- <vxe-button v-if="row.Status !== 0" type="button" status="primary" content="關閉" @click="() => { grid.isModalPopup = false; grid.editingRow = null; }"></vxe-button>
            <vxe-button v-else type="submit" status="primary" content="確認" @click="$refs.form.validate(errMap => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })"></vxe-button> -->
            <!-- <vxe-button type="reset" content="重置" @click="$refs.form.dispatchEvent('reset')"></vxe-button> -->
          </template>
          <template #filter-name="{ column, $panel }">
            <div
              v-for="(option, index) in column.filters"
              :key="index"
              class="flex item-center justify-center"
            >
              <input
                type="checkbox"
                class="input input--switch border mt-4 ml-2"
                :checked="option.checked"
                @input="grid.changeFilterEvent($event, column, option, $panel, true)"
              />
              <div class="relative m-2">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
                >
                  {{ option.label }}
                </div>
                <input
                  v-model.trim="option.data"
                  :disabled="!option.checked"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  placeholder="請輸入文字"
                  @input="grid.changeFilterEvent($event, column, option, $panel)"
                />
              </div>
            </div>
          </template>
          <template #operate="{ row }">
            <button
              v-if="row.Status !== 0"
              class="button rounded-full mr-1 bg-blue-600 text-white"
              title="查看"
              @click.stop="grid.edit(row)"
            >
              <FontAwesome
                icon="book-open"
                class="w-4 h-4"
              />
            </button>
            <div
              v-else
              class="flex justify-center"
            >
              <button
                class="button rounded-full mr-1 bg-blue-600 text-white"
                title="編輯"
                @click.stop="grid.edit(row)"
              >
                <FontAwesome
                  icon="highlighter"
                  class="w-4 h-4"
                />
              </button>
              <button
                type="button"
                class="button rounded-full bg-red-600 text-white"
                title="刪除"
                @click.stop="grid.remove(row)"
              >
                <FontAwesome
                  icon="trash"
                  class="w-4 h-4"
                />
              </button>
            </div>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <vxe-modal
      resize
      v-model="flowModal.visible"
      width="70%"
      title="文件流程"
      height="600"
      show-zoom="true"
    >
      <InProgressFlowTable
        ref="flowGrid"
        v-bind="flowModal.gridOptions"
        :modelValue="flowModal.visible"
        :contentType="flowModal.selectedRow.ContentType"
        :read="flowModal.selectedRow.Status != 0"
        :sid="flowModal.selectedRow.Id"
      />
    </vxe-modal>
    <!-- <vxe-modal v-model="flowModal.visible" width="60%" height="500" title="新增客戶訂單" show-zoom="true">
      <FlowTable ref="flowGrid" :visible="flowModal.visible" @submit="flowModal.save" />
    </vxe-modal>
    <vxe-modal v-model="deleteModal.visible" width="30%" height="350" title="刪除客戶訂單" show-zoom="true">
      <DeleteTable ref="deleteGrid" :visible="deleteModal.visible" @save="deleteModal.save" />
    </vxe-modal> -->
  </div>
</template>

<script lang="ts">
/* eslint-disable */
import CloudFun, { computed, defineComponent, ref, Condition, Operator, reactive, onMounted } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import CheckBoxList from '@/cloudfun/components/CheckBoxList.vue'
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import { getformOption, getType } from '@/libs/formItems';
import InvoiceQuarterlyItemTable from "@/components/document/InvoiceQuarterlyItemTable.vue";
import BOMUsageQuantityModifyItemTable from "@/components/document/BOMUsageQuantityModifyItemTable.vue";
import MaterialSubstitutesItemsTable from "@/components/document/MaterialSubstitutesItemsTable.vue";
import AssessmentItemsTable from "@/components/document/AssessmentItemsTable.vue";
import ProductNumberModifuItemsTable from "@/components/document/ProductNumberModifuItemsTable.vue";
import PurchaseRequisitionItemsTable from "@/components/document/PurchaseRequisitionItemsTable.vue";
import OrderItemsTable from "@/components/document/OrderItemsTable.vue";
import CustomerCreditItemsTable from "@/components/document/CustomerCreditItemsTable.vue";
import InProgressFlowTable from "@/components/document-flow/InProgressFlowTable.vue";
import DocumentFileUpload from "@/components/file/DocumentFileUpload.vue";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    InvoiceQuarterlyItemTable,
    BOMUsageQuantityModifyItemTable,
    MaterialSubstitutesItemsTable,
    AssessmentItemsTable,
    ProductNumberModifuItemsTable,
    PurchaseRequisitionItemsTable,
    OrderItemsTable,
    CustomerCreditItemsTable,
    InProgressFlowTable,
    DocumentFileUpload,
  },
  setup() {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [{ field: 'Name' }, { field: 'Description' }]
    const selectedType = ref<any>('');

    const user = CloudFun.current?.user;
    const policy = CloudFun.current?.policy;

    const checkPermissions = (key: string) => {
      // console.log(user?.Permissions)
      try {
        return user && policy?.isApproved(user, key);
      } catch {
        return false;
      }
    };

    const canCreate = checkPermissions("DocumentFlowCreate");
    const canUpdate = checkPermissions("DocumentFlowUpdate");
    const canDelete = checkPermissions("DocumentFlowDelete");

    const gridOptions: GridOptions = {
      id: 'document',
      title: '客戶訂單',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      canCreate: canCreate,
      canUpdate: canUpdate,
      canDelete: canDelete,
      multiselect: false,
      printConfig: {
        sheetName: '清單',
        columns: printColumns,
        modes: ['current', 'selected', 'all']
      },
      exportConfig: {
        filename: '清單',
        type: 'csv',
        types: ['html', 'csv'],
        mode: 'all',
        modes: ['current', 'selected', 'all'],
        columns: printColumns
      },
      columns: [
        { field: 'Title', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Type", title: "文件類型", showHeaderOverflow: true, showOverflow: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.DocumentType).find(e => e.Value === cellValue)?.Name : undefined },
        { field: "ContentType", title: "表單類型", showHeaderOverflow: true, showOverflow: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.DocumentContentType).find(e => e.Value === cellValue)?.Name : undefined },
        { field: 'Submitter.Person.Name', title: '申請人', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'CurrentSignatureLevel', title: '目前簽核層級', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Status', title: '簽核狀態', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.DocumentStatus).find(e => e.Value === cellValue)?.Name : undefined },
        {
          title: '文件流程',
          width: '100',
          slots: { default: "flow" }, align: 'center'
        },
      ],
      promises: {
        query: model ? async (params) => {
          params.condition!.and("ContentType", Operator.Equal, 14);
          if (params?.sortings?.length === 0) {
            params.sortings = [{ column: 'CreatedTime', order: 1 }];
          }
          if(user && !user.IsAdmin && !user.isUnitManager && !user.isDepManager && !user.isHR) {
            params.condition = new Condition("SubmitterId.Value", Operator.Equal, user.Id).and(params.condition!);
          }
          if(user && user.userDepartmentId && (user.isUnitManager || user.isDepManager) && !user.isHR) {
            console.log('user-userDepartmentId', user.userDepartmentId);
            params.condition = new Condition("Submitter.DepartmentId", Operator.Equal, user.userDepartmentId).and(params.condition!);
          }
          return await model.dispatch('document/query', params);
        } : undefined,
        queryAll: model ? () => model.dispatch('document/query') : undefined,
        save: model
          ? (params) => model.dispatch('document/save', params)
          : undefined
      },
      modalConfig: { width: "60%", showFooter: true, fullscreen: true }
    }

    const form = ref<any>();
    const readType = ref(false);
    const defaultFormOptions: VxeFormProps = {
      items: [
        {
          field: 'Title',
          title: '名稱',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字' },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'SubmittingTime',
          title: '提交時間',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
        },
        {
          field: 'Type',
          title: '文件類型',
          span: 12,
          itemRender: { name: "$select", props: {}, options: model ? Object.values(model.enums.DocumentType).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'ContentType',
          title: '表單類型',
          span: 12,
          itemRender: { name: "$select", props: { disabled: true }, options: model ? Object.values(model.enums.DocumentContentType).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'SubmitterId',
          title: '申請人',
          span: 12,
          slots: { default: "column-submitter" }
        },
        {
          field: 'CurrentSignatureLevel',
          title: '目前簽核層級',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '', type: 'number', disabled: true },
          }
        },
        {
          field: 'Status',
          title: '簽核狀態',
          span: 12,
          itemRender: { name: "$select", props: { disabled: true }, options: model ? Object.values(model.enums.DocumentStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'XmlData.CustomerShortName',
          title: '客戶名稱',
          span: 12,
          slots: { default: "column-customName" }
        },
      ],
      rules: {
        Title: [{ type: 'string', required: true }],
        SubmittingTime: [{ required: true }],
        Type: [{ required: true }],
        ContentType: [{ required: true }],
      }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: 'right',
    }

    const userSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Person.Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("employee/find", value), // eslint-disable-line
        query: params => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          return model!.dispatch("employee/query", params) // eslint-disable-line
        }
      }
    };

    const departmentSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("department/find", value), // eslint-disable-line
        query: params => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          return model!.dispatch("department/query", params) // eslint-disable-line
        }
      }
    };
    const customNameSelectOption: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("customer/find", value), // eslint-disable-line
        query: params => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          return model!.dispatch("customer/query", params) // eslint-disable-line
        }
      }
    };

    const initAddData = (row: any, callback: any) => {
      row.SubmitterId = user?.Id;
      row.SubmittingTime = new Date();
      row.Type = getType(14);

      row.Status = 0;
      readType.value = false;
      row.ContentType = 14;
      row.XmlData = {} as any;
      row.XmlData.InvoiceQuarterlyItems = [] as any;
      formOptions.items = defaultFormOptions.items;
      formOptions.rules = defaultFormOptions.rules;

      var contentTypeOption = getformOption(row.ContentType, model, row.Status == 0 ? false : true) as any;
      let defaultCopy = JSON.parse(JSON.stringify(defaultFormOptions));
      Array.prototype.push.apply(defaultCopy.items, contentTypeOption.items);
      for (const key in contentTypeOption.rules) defaultCopy.rules[key] = contentTypeOption.rules[key];
      formOptions.items = defaultCopy.items;
      formOptions.rules = defaultCopy.rules;

      grid.value.isModalPopup = true;
      callback();
    }

    const setDefaultReadOnly = (data: any) => {
      data.items[0].itemRender.props.readonly = true;
      data.items[1].itemRender.props.readonly = true;
      data.items[2].itemRender.props.disabled = true;
      data.items[3].itemRender.props.disabled = true;
      return data;
    }

    const onGridEdit = async (row: any, callback: any) => {
      const entity = row.Id ? await model?.dispatch('document/find', row.Id) : undefined
      Object.assign(row, entity);
      if (row.Id) {
        readType.value = false;
        var contentTypeOption = getformOption(row.ContentType, model, row.Status == 0 ? false : true) as any;
        let defaultCopy = JSON.parse(JSON.stringify(defaultFormOptions));
        if (row.Status != 0) { //唯讀
          readType.value = true;
          defaultCopy = setDefaultReadOnly(defaultCopy);
        }
        Array.prototype.push.apply(defaultCopy.items, contentTypeOption.items);
        for (const key in contentTypeOption.rules) {
          defaultCopy.rules[key] = contentTypeOption.rules[key];
        }
        formOptions.items = defaultCopy.items;
        formOptions.rules = defaultCopy.rules;
        fileModal.show(row);
      }
      callback()
    }

    const itemsTable = ref<any>();
    const ProductModifyItemsTable = ref<any>();
    const FinishedProductModifyItemsTable = ref<any>();
    const ReRollModifyItemsTable = ref<any>();
    const MaterialSubstitutesItemsTable = ref<any>();
    const AssessmentItemsTable = ref<any>();
    const ProductNumberModifuItemsTable = ref<any>();
    const PurchaseRequisitionItemsTable = ref<any>();
    const OrderItemsTable = ref<any>();
    const CustomerCreditItemsTable = ref<any>();

    const formSubmit = (row: any, submit: any) => {
      if (row.ContentType == 14) { //客戶訂單
        var orderItems = OrderItemsTable?.value.getData();
        if (orderItems)
          row.XmlData.Items = orderItems;
      }
      submit();
    }

    onMounted(() => {
      selectedType.value = 14;
    })

    /** 資訊需求申請使用 */
    const typesSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.ITRequirementItem).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.ITRequirementItem).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };
    const reasonsSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.ITRequirementICauseReason).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.ITRequirementICauseReason).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };
    const annexSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.ITRequirementIAnnex).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.ITRequirementIAnnex).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };

    const wrokOrderItemsSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.WorkOrderType).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.WorkOrderType).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };

    const applicationTypesSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.PaymentApplicationType).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.PaymentApplicationType).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };

    const purchaseRequisitionTypeSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.PurchaseRequisitionType).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.PurchaseRequisitionType).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };

    const sendDocument = async (row: any) => {
      if (row.Id && confirm("確定要送出文件嗎?")) {
        try {
          await model?.dispatch('document/send', row.Id);
          CloudFun.send("info", {
            subject: "執行成功",
            content: `已送出文件`,
          });
          grid.value.isModalPopup = false;
          grid.value.editingRow = null;
          grid.value.refresh();
        } catch (e: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: e,
          });
        }
      }
    }

    const fileGrid = ref<any>()
    const fileModal = reactive({
      visible: false,
      selectedRow: {} as any,
      uploadOptions: {
        promises: {
          async query() {
            var condition = new Condition("DocumentId", Operator.Equal, fileModal.selectedRow.Id.toString());
            const sortings = [{ column: "CreatedTime", order: 0 }];
            return await model?.dispatch('files/query', {
              condition: condition,
              sortings: sortings,
            });
          },
          insert(formData: FormData) {
            return model?.dispatch('files/insert', { formData, mode: 'other', entityId: 'Document_' + fileModal.selectedRow.Id, fileType: 2 });
          },
          delete(id: number) {
            return model?.dispatch('files/delete', id);
          }
        }
      },
      show(row: any) {
        fileModal.selectedRow = row;
        if (fileGrid.value) fileGrid.value.refresh();
      }
    })

    const flowGrid = ref<any>()
    const flowModal = reactive({
      visible: false,
      selectedRow: {} as any,
      gridOptions: {
        promises: {
          query: model ? (params) => {
            params.condition = new Condition("DataMode", Operator.Equal, 0).and("DocumentId", Operator.Equal, flowModal.selectedRow.Id).and("NotifyOnly", Operator
              .Equal, false
            )
              .and(params.condition!);
            if (!params.sortings || params.sortings?.length === 0) {
              params.sortings = [{ column: "Level", order: 0 }, { column: "CreatedTime", order: 0 }];
            }
            // var selectedStatus = flowGrid?.value.getSelectedStatus();
            // if (selectedStatus != null) {
            //   params.condition.and("Status", Operator.Equal, selectedStatus)
            // }
            return model.dispatch("inProgressFlow/query", params);
          } : undefined,
          queryAll: model ? () => model.dispatch('inProgressFlow/query') : undefined,
          save: model ? (params) => model.dispatch('inProgressFlow/save', params) : undefined
        }
      } as GridOptions,
      show(row: any) {
        flowModal.visible = true;
        flowModal.selectedRow = row;
        if (flowGrid.value) flowGrid.value.refresh();
      }
    })

    return {
      canCreate,
      grid,
      form,
      gridOptions,
      formOptions,
      userSelectorOptions,
      departmentSelectorOptions,
      selectedType,
      model,
      initAddData,
      onGridEdit,
      itemsTable,
      formSubmit,
      readType,
      annexSelectorOptions,
      reasonsSelectorOptions,
      typesSelectorOptions,
      wrokOrderItemsSelectorOptions,
      ProductModifyItemsTable,
      ReRollModifyItemsTable,
      FinishedProductModifyItemsTable,
      MaterialSubstitutesItemsTable,
      applicationTypesSelectorOptions,
      AssessmentItemsTable,
      ProductNumberModifuItemsTable,
      PurchaseRequisitionItemsTable,
      purchaseRequisitionTypeSelectorOptions,
      OrderItemsTable,
      CustomerCreditItemsTable,
      sendDocument,
      flowGrid,
      flowModal,
      fileGrid,
      fileModal,
      customNameSelectOption
    }
  },
  methods: {
    hideBatchDropDown() {
      cash('#batch-dropdown').dropdown('hide')
    }
  }
})
</script>
